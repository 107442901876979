.modal {
    pointer-events: none;    
}

.modal-header {
    border-bottom: unset;
}

.modal-title {
    font-size: 16px;
    font-weight: 700;
}

.modal-footer {
    border-top: unset;
}

.modal-footer button {
    font-size: 12px;
}

.draggable-modal .modal-header{
    cursor: all-scroll;
}