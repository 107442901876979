.send-email-modal .modal-header, .send-sms-modal .modal-header {
    border-bottom: 1px solid rgba(198, 198, 198, 0.5);
}

.send-email-modal .modal-dialog, .send-sms-modal .modal-dialog {
    min-width: 850px;
}

.send-email-modal .value, .send-sms-modal .value {
    margin-bottom: 12px;
}

.mail-editor-wrapper {
    border: 1px solid rgba(198, 198, 198, 0.5);
    border-radius: 4px;
    margin-bottom: 10px;
}

.mail-editor {
    min-height: 500px;
    max-height: 800px;
}

.send-email-modal textarea.mail-editor {
    width: 100%;
    border: 1px solid rgba(198, 198, 198, 0.5);
    border-radius: 4px;
    min-height: 550px;
}

.send-email-modal .info {
    margin-bottom: 10px;
}

.sms-editor {
    min-height: 400px;
    max-height: 600px;
}