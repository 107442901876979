.loan-picker {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    flex: 1;
    margin-right: -30px;
    min-width: 159px;
}

.loan-picker .loan-picker-item {
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 12px;
    margin: 1px;
    cursor: pointer;
}

.loan-picker .loan-picker-item .status {
    color: #ffffff;
    height: 33px;
    line-height: 33px;
    background-color: #559AF4;
    font-size: 10px;
}

.loan-picker .loan-picker-item .status.status-gray {
    color: #9EA8B2;
    background-color: #EEEEEE;
}

.loan-picker .loan-picker-item .status.status-blue {
    background-color: #559AF4;
}

.loan-picker .loan-picker-item .status.status-orange {
    background-color: #FF9900;
}

.loan-picker .loan-picker-item .status.status-turquoise {
    background-color: #5BC0DE;
}

.loan-picker .loan-picker-item .status.status-green {
    background-color: #42D253;
}

.loan-picker .loan-picker-item .status.status-red {
    background-color: #F05B59;
}

.loan-picker .loan-picker-item .status.status-lightgoldenrodyellow {
    color: #9EA8B2;
    background-color: #FAFAD2;
}

.loan-picker .loan-picker-item .status.status-moccasin {
    color: #9EA8B2;
    background-color: #FFE4B5;
}

.loan-picker .loan-picker-item .date-container {
    color: #9EA8B2;
    background-color: #FFFFFF;
    height: 26px;
    line-height: 26px;
    text-transform: uppercase;
    transition: background-color 300ms ease-out;
}

.loan-picker .loan-picker-item:hover .date-container, .loan-picker .loan-picker-item.selected .date-container {
    background-color: #E7EBEE;
    transition: background-color 300ms ease-in;
}

.loan-picker .scroll-button {
    width: 25px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 2px;
}

.loan-picker .loan-picker-item.hidden {
    opacity: 0.65;
}