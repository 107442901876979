.recipients-tab-content .error-text {
    font-weight: bold;
}

.recipients-tab-content .recipients-filter .readonly .substatuses-input-field-filter {
    display: flex;
    flex-wrap: wrap;
}

.recipients-tab-content .recipients-filter .readonly .substatuses-input-field-filter .status {
    margin-bottom: 4px;
}

.recipients-tab-content .recipients-filter .readonly {
    display: grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    align-items: flex-end;
    grid-gap: 12px;
}

.recipients-tab-content .recipients-filter .edit {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    grid-gap: 12px;
    position: relative;
}


.recipients-tab-content .recipients-filter .edit > * {
    max-width: 250px;
}

.recipients-tab-content .recipients-filter .edit .multiselect {
    border: 1px solid #ced4da;
    background-color: #ffffff;
}

.recipients-tab-content .recipients-filter .form-control {
    background-color: #ffffff;
}

.recipients-tab-content .recipients-filter .filter-active .form-control {
    border-color: #559AF4;
}

.recipients-tab-content .recipients-filter .edit .filter-active.multiselect {
    border: 1px solid #559AF4;
}

.recipients-tab-content .recipients-table {
    margin: 24px 0;
    position: relative;
}