.generic-tabs {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
}

.generic-tabs .generic-tab {
    padding: 4.5px 12.5px 3.5px;
    color: #9ea8b2;
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-weight: 900;
    cursor: pointer;
    border-top: 1px solid #f1f1f1;
    border-left: 0.5px solid #f1f1f1;
    border-right: 0.5px solid #f1f1f1;
    transition: background-color 300ms ease-out;
}

.generic-tabs .generic-tab:first-child {
    border-left: 1px solid #f1f1f1;
}

.generic-tabs .generic-tab:last-child {
    border-right: 1px solid #f1f1f1;
}

.generic-tabs .generic-tab:hover, .generic-tabs .generic-tab.active {
    text-decoration: none;
    background-color: #e7ebee;
    transition: background-color 300ms ease-in;
}

.generic-tabs .generic-tab .selection-dot {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #F05B59;
    display: inline-block;
    margin-left: 9px;
}

.generic-tabs .generic-tab.positive .selection-dot {
    background-color: #42D253;
}