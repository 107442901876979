.add-co-applicant-button-wrapper {
    height: 100%;
    max-width: 380px;
    min-width: 100px;
    flex: 1;
    margin: auto 0;
    font-family: 'Nunito Sans';
    font-weight: 700;
    color: #707070;
}

.add-co-applicant-button {
    border-radius: 25px;
    height: 50px;
    width: 50px;
    transition: background-color 300ms ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    margin: 10px auto 0;
}

.add-co-applicant-button:hover {
    transition: background-color 300ms ease-in;
    background-color: #f3f2f2;
}