.insurance-view {
    margin-top: 19px;
    padding:0;
    padding-bottom: 5px;
    width: 1641px;
}

.insurance-view>div {
    padding-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

.insurance-view>.top-panel {
    display: flex;
    min-height: 181px;
}

.insurance-view>.top-panel>div {
    display: flex;
    flex-wrap: wrap;
}

.insurance-view>.top-panel>div:last-child {
    justify-content: flex-end;
}

.insurance-view .loan-picker {
    min-width: 553px;
    max-height: 60px;
    align-self: flex-end;
    margin-bottom: 1px;
}

.insurance-view .generic-tabs {
    align-self: flex-end;
}

.insurance-view>.content-panel {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 25px;
}

.insurance-view>.content-panel>div+div {
    margin-left: 20px;
}

.insurances {
    width: 1048px;
    padding: 15px 0px 1px;
}

.insurances table {
    width: 100%;
}

.insurances table tr th {
    text-align: left;
    padding: 0 27px 10px 30px;
    color: #707070;
}

.insurances table tr td {
    border-top: 1px solid rgba(198, 198, 198, 0.5);
    text-align: right;
    height: 45px;
    font-size: 14px;
}

.insurances table tr td.expanded {
    border: 0;
}

.insurances table tr td:first-child {
    text-align: left;
    font-weight: 700;
    padding-left: 30px;
}

.insurances table tr td:last-child {
    padding-right: 30px;
}

.insurances .status-icon svg {
    margin-right: 10px;
    opacity: 0.3;
}

.insurance-status {
    margin-left: 3px;
}

.insurances .status, .insurance-details-modal .status {
    display: inline-block;
    padding: 2px 10px 1px 10px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
    border-radius: 0.25em;
}

.insurances .insurance-status, .insurance-details-modal .insurance-status {
    border-radius: 15px;
    text-transform: none;
}

.insurances .insurance-buttons {
    float: right;
    color: #707070;
    margin-right: -2px;
    font-size: 16px;
}

.insurances .insurance-details {
    clear: both;
    margin: 20px 0 5px 0;
    width: 900px;
}

.insurance-details-modal .modal-header, .cancel-insurance-modal .modal-header {
    border-bottom: 1px solid rgba(198, 198, 198, 0.5);
}

.insurance-details-modal .modal-body {
    padding-left: 0;
    padding-right: 0;
}

.insurance-details-modal .modal-dialog {
    min-width: 700px;
}

.insurance-details-modal .row:first-child .col {
    max-width: 398px;
}

.cancel-insurance-modal .value input {
    width: 100px;
}

.insurance-details {
    display: flex;
    flex-wrap: wrap;
}

.insurance-details>div {
    min-width: 294px;
    margin-bottom: 15px;
}

.insurance-details-modal .title {
    font-weight: 700;
    color: #707070;
    margin: 8px 0;
    display: block;
}

.insurance-details-modal .consumer-liquidity {
    display: grid;
    grid-template-columns: 50% calc(50% - 15px);
    grid-column-gap: 15px;
    width: 100%;
}

.insurance-details-modal .consumer-liquidity > * {
    position: relative;
    padding-bottom: 48px;
}

.insurance-details-modal .consumer-liquidity .incomes-fields,
.insurance-details-modal .consumer-liquidity .expenses-fields {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.insurance-details-modal .consumer-liquidity .total {
    position: absolute;
    border-top: 1px solid black;
    inset: auto 0 0 0;
}

.insurance-details-modal .insurance-details>div {
    min-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
}

.insurance-details-modal .consumer-debts {
    width: 100%;
    padding-top: 25px;
}

.insurance-details-modal .consumer-debts .total {
    padding-top: 10px;
    padding-left: 20px;
    font-weight: bold;
}

.insurance-details-modal .consumer-debts table tr:first-child {
    font-weight: bold;
}

.insurances table.insurance-history {
    font-size: 14px;
    text-align: left;
    font-weight: normal;
    border-top: 1px solid rgba(198, 198, 198, 0.5);
    margin-top: 10px;
}

.insurances table.insurance-history td {
    border: 0;
    text-align: left;
}

.insurances table.insurance-history td:first-child {
    width: 40px;
    padding-left: 10px;
    color: #707070;
}

.insurances table.insurance-history tr:nth-child(even) {
    background-color: #F9F9F9;
}

.insurances table.insurance-history td:last-child {
    text-align: right;
    padding-right: 3px;
    white-space: nowrap;
    padding-left: 10px;
    width: 100px;
}

.error-text {
    color: #f05b59;
}

.paid-text {
    color: #42D253;
}

.invoice-indicator {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #f05b59;
    margin-right: 10px;
    margin-bottom: 1px;
}