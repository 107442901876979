.bank-replies-container {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.bank-replies-table-head {
    text-align: left;
    padding: 0px 27px 10px 29px;
    color: #707070;
}

.bank-replies-table-head.selectable {
    padding-left: 3px;
}

.bank-replies-table-head>div:last-child {
    float: right;
}

.bank-replies-table-head>div>span:not(:first-child) {
    margin-left: 5px;
}

.bank-replies-container>tbody>tr.bank-row>td {
    border-top: 1px solid rgba(198, 198, 198, 0.5);
    text-align: right;
    height: 45px;
    font-size: 14px;
}

.bank-replies-container>tbody>tr.bank-row>td:first-child {
    text-align: left;
    font-weight: 700;
}

.bank-replies-container>tbody>tr.bank-row>td:first-child>span {
    vertical-align: text-bottom;
}

.bank-replies-container>tbody>tr.bank-row>td:first-child>input {
    margin-right: 3px;
}

.bank-replies-container>tbody>tr.bank-row>td:last-child {
    width: 120px;
}

.bank-replies-container>tbody>.bank-row-expansion>td {
    padding-left: 30px;
    padding-right: 30px;
}

.bank-replies-container>.bank-row>td {
    display: table-cell;
    margin: 10px 0;
}

.bank-replies-container svg {
    color: #707070;
}

.bank-row>td:first-child {
    padding-left: 30px;
}

.bank-row>td.selectable:first-child {
    padding-left: 3px;
}

.bank-row>td:last-child {
    padding-right: 30px;
}

.bank-row .reply-status {
    text-align: left;
}

.bank-row .reply-status span {
    background-color: #3490DC;
    border-radius: 15px;
    padding: 2px 10px 1px;
    color: white;
    font-weight: 900;
    font-size: 12px;
}

.bank-row .reply-status.status-gray span {
    color: #9EA8B2;
    background-color: #EEEEEE;
}

.bank-row .reply-status.status-blue span {
    background-color: #559AF4;
}

.bank-row .reply-status.status-orange span {
    background-color: #FF9900;
}

.bank-row .reply-status.status-turquoise span {
    background-color: #5BC0DE;
}

.bank-row .reply-status.status-green span {
    background-color: #42D253;
}

.bank-row .reply-status.status-red span {
    background-color: #F05B59;
}

.bank-row .status-icon svg {
    margin-right: 10px;
    opacity: 0.3;
}

.bank-row.error {
    background-color: #F05B59;
}

.bank-row.warning {
    background-color: #ff9900;
}

.response-message.error {
    color: #F05B59;
}

.response-message.warning {
    color: #ff9900;
}

.bank-replies-form {
    margin-bottom: 15px;
}

.bank-replies-container .generic-dropdown button {
    height: 35px !important;
}

.bank-replies-form .row:last-of-type .col {
    margin-bottom: 0 !important;
}

.bank-replies-form-add-clawback {
    margin-top: 8px;
    font-size: 12px;
}

.bank-replies-form-comment {
    margin-top: 8px;
    font-size: 14px;
    text-align: left;
}

.bank-replies-form-comment > div {
    height: 24px;
}

.bank-report-history {
    max-height: 410px;
    overflow: auto;
    border-top: 1px solid rgba(198, 198, 198, 0.5);
    width: 100%;
    margin-top: 10px;
    max-width: 589px;
}

.bank-report-history table,
.bank-replies-refinances table {
    width: 100%;
    font-size: 14px;
    text-align: left;
}

.bank-report-history table tbody tr td {
    padding: 0;
    height: 41px;
}

.bank-report-history table tbody tr td:first-child {
    width: 39px;
}

.bank-report-history table tbody tr td:first-child svg {
    margin: 12px 12px 12px 11px;
    display: block;
}

.bank-report-history table tbody tr:nth-child(even),
.bank-replies-refinances table tbody tr:nth-child(even) {
    background-color: #F9F9F9;
}

.bank-report-history table tbody tr td button {
    padding: 0;
}

.bank-report-history .bank-replies-status-comment {
    margin-left: 3px;
}

.bank-report-history table tbody tr td:last-child {
    text-align: right;
    padding-right: 3px;
    white-space: nowrap;
    padding-left: 10px;
}

.bank-replies-refinances {
    margin-bottom: 24px;
}

.bank-replies-refinances .title {
    font-size: 14px;
    text-align: left;
    font-weight: 700;
}

.bank-replies-refinances table thead tr {
    background-color: #F2F5F7;
    text-transform: uppercase;
    color: #9EA8B2;
    font-size: 12px;
}

.bank-replies-refinances table thead tr th:first-child,
.bank-replies-refinances table tbody tr td:first-child {
    padding: 12px 0 12px 13px;
}

.bank-replies-refinances table tbody tr td .value > span {
    font-weight: initial;
    color: initial;
}

.bank-replies-form-header .response-message {
    font-weight: normal;
    font-size: 14px;
    min-width: 40px;
    display: flex;
    flex-grow: 1;
}

.bank-replies-form-header .dropdown {
    flex-shrink: 0;
}

.column-card .bank-replies-form-header.card-heading {
    max-width: 591px; 
    margin-bottom: 10px;
    column-gap: 12px;
}

.column-card .bank-replies-form-header.card-heading .options {
    font-size: 14px;
    display: flex;
}

.bank-replies-container .fa-rotate, 
.bank-replies-container .fa-eraser, 
.bank-replies-container .fa-check-double {
    font-size: 18px;
}