.customer-advisor {
    font-family: 'Nunito Sans';
    text-align: left;
    color: #9EA8B2;
    font-weight: 900;
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
    margin-bottom: 3px;
    padding: 2px 10px 1px;
    border-radius: 15px;
}

.customer-advisor:hover {
    background-color: rgba(238, 238, 238, 1);
    transition: linear .3s;
}

.customer-advisor .photo {
    float: left;
    margin-right: 5px;
}

.customer-advisor-team {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}