.property-information {
    text-align: left;
    color: #707070;
    font-size: 14px;
    margin-right: 50px;
    margin-left: auto;
    width: 130px;
    position: relative;
    min-height: 80px;
}

.property-information .details {
    text-align: left;
    color: #707070;
    font-size: 14px;
    position: absolute;
    top: 31px;
}

.property-information .details .value {
    margin-bottom: 5px;
}


.property-information .property-title {
    font-size: 16px;
    font-weight: 700;
    width: 100px;
    cursor: pointer;
}

.property-information.right-4 {
    margin-right: 110px;
}

.property-information.right-5 {
    margin-right: 240px;
}

.property-information.left-minus {
    margin-right: initial;
    margin-left: -30px;
}

.pi-external-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}