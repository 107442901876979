.design-tab-content {
    width: 50%;
}

.design-tab-content .content-length-info {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 12px;
    color: #707070;
    margin-top: -12px;
}

.design-tab-content .content-length-info .error {
    color: #dc3545;
}

.design-tab-content > *:not(:last-child) {
    margin-bottom: 12px;
}

.design-tab-content .tox-tinymce {
    border: 1px solid #ced4da;
}

.design-tab-content .invalid .tox-tinymce {
    border: 1px solid #dc3545;
}

.design-tab-content .email-template-preview {
    border: 1px solid #ced4da;
    background: #e9ecef;
    border-radius: 0.375rem;
    overflow: auto;
}
