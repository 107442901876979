ul.picker-list {
    display: inline;
}

ul.picker-list li {
    list-style-type: none;
    display: inline;
    margin: 0 5px;
    cursor: pointer;
}

ul.picker-list li.active, ul.picker-list li:hover {
    text-decoration: underline;
}

ul.picker-list li.inactive:hover {
    cursor: default;
    text-decoration: none;
}

/* 
ul.picker-list.dark, span.showing-page.dark {
     color: #fff; 
}

ul.picker-list.light, span.showing-page.light {
     color: #333333; 
} */

span.showing-page, ul.picker-list li {
    font-size: 14px;
    font-family: 'Nunito Sans';
}