.applicant-search-result-list {
    margin-top: 15px;
    background-color: white;
}

.applicant-search-result-list .applicant-search-result {
    color: #707070;
    padding: 5px;
    cursor: pointer;
}

.applicant-search-result-list .applicant-search-result:hover {
    background-color: #f2f5f7 !important;
}

.applicant-search-result-list .applicant-search-result:nth-child(2n+2) {
    background-color: #f9f9f9;
}

.applicant-search-result-list .applicant-search-result .applicant-data {
    margin-left: 5px;
}