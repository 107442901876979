.mortgage-calculator .title {
    font-weight: 700;
    color: #707070;
    margin: 8px 0;
    display: block;
}

.mortgage-calculator *:not(.new-loan) .title, 
.mortgage-calculator .title:not(:first-child) {
    margin-top: 24px;
}

.mortgage-calculator .row + .row {
    margin-top: 8px;
}

.mortgage-calculator .value.inline-with-editable + .description {
    margin-bottom: 2px;
}

.mortgage-calculator .value.inline-with-editable:not(.form-group) {
    margin-top: 15px;
}