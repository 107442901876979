.clear-icon {
    opacity: 0;
    position: absolute;
    font-size: 12px;
    cursor: pointer;
    color: #c6c6c6 !important;
    transition: opacity .3s linear, color .3s ease-in-out;
    z-index: 99;
}

.clear-icon:hover {
    color: #707070 !important;
}

.value {
    position: relative;
}

.value:hover .clear-icon {
    opacity: 1;
}

.value input {
    text-align: left;
    height: auto;
    padding: 0 0 0 5px;
    font-size: 14px;
    line-height: 33px;
}

.input-group-text {
    font-size: 14px;
}