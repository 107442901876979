.marketing-lists-view .dropdown.actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 1px;
}

.marketing-lists-view .table-container {
    position: relative;
    border-top: none;
}

table.marketing-lists-table {
    font-size: 14px;
    text-align: left;
    font-family: 'Nunito Sans';
    margin:0;
    white-space: nowrap;
}

table.marketing-lists-table thead {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f2f5f7;
    color: #9ea8b2;
}

table.marketing-lists-table thead th {
    padding-left: 0;
    white-space: nowrap;
}

table.marketing-lists-table thead th:first-child {
    padding-left: 20px;
}

table.marketing-lists-table tbody tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

table.marketing-lists-table tbody tr:nth-of-type(2n+1) {
    background-color: #ffffff;
}

table.marketing-lists-table tbody tr:hover {
    background-color: #f2f5f7;
    cursor: pointer;
}

table.marketing-lists-table tbody tr td {
    padding: 10px 8px 10px 0;
    vertical-align: middle;
}

table.marketing-lists-table tbody tr td:first-child {
    padding-left: 20px;
}

table.marketing-lists-table ul {
    margin: 0;
}