.mortgage-calculator .total {
    padding-left: 13px;
    border-top: 1px solid #707070;
    margin-top: 8px;
    padding-top: 4px;
}

.mortgage-calculator .expenses-fields {
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    grid-column-gap: 30px;
    grid-row-gap: 8px;
}