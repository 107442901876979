.popover {
    font-family: "Nunito Sans";
}

.popover .popover-header {
    font-weight: 700;
    background-color: transparent;
}

.tooltip,
.popover {
    /*This property is overriden to position: absolute after tooltip is created*/
    /*However this prevents tooltip doing strange flickering*/
    /*And scroll is not shown when overlay is attached*/
    position: fixed;
}