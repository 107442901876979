.campaign-input-wrapper {
    justify-content: space-between;
    flex-direction: column;
}

.campaign-input-wrapper::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
    height: 0;
}

.campaign-input-container {
    display: flex;
    justify-content: space-between;
}

.campaign-input-container input {
    max-width: 100%;
}