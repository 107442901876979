.toggle-button {
    display: flex;
    align-items: center;
    margin: 0 5px;
}

.toggle-button .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin: 0 5px;
  }
  
  .toggle-button .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-button .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 17px;
  }
  
  .toggle-button .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  .toggle-button input:checked + .slider {
    background-color: #2196F3;
  }
  
  .toggle-button input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  .toggle-button input:checked + .slider:before {
    transform: translateX(15px);
  }