.string-collection-input-field {
    cursor: pointer;
}

.string-collection-input-field .text-field {
    pointer-events: none;
}

.string-collection-input-field .text-field input {
    height: 32px;
}