.campaigns-view .toolbar {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, calc(20% - 8px));
    grid-column-gap: 10px;
    position: relative;
}

.campaigns-view .toolbar > * {
    max-width: 200px;
}

.campaigns-view .toolbar .dropdown.actions {
    position: absolute;
    right: 1px;
}

hr.table-separator {
    margin-top: 11px;
}

.campaigns-view .table-container {
    position: relative;
}

table.campaigns-table {
    font-size: 14px;
    text-align: left;
    font-family: 'Nunito Sans';
    margin:0;
    white-space: nowrap;
}

table.campaigns-table thead {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f2f5f7;
    color: #9ea8b2;
}

table.campaigns-table thead th {
    padding-left: 0;
    white-space: nowrap;
}

table.campaigns-table thead th:first-child {
    padding-left: 20px;
}

table.campaigns-table tbody tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

table.campaigns-table tbody tr:nth-of-type(2n+1) {
    background-color: #ffffff;
}

table.campaigns-table tbody tr:hover {
    background-color: #f2f5f7;
    cursor: pointer;
}

table.campaigns-table tbody tr td {
    padding: 10px 8px 10px 0;
    vertical-align: middle;
}

table.campaigns-table tbody tr td:first-child {
    padding-left: 20px;
}

table.campaigns-table ul {
    margin: 0;
}

table.campaigns-table .status {
    border-radius: 15px;
    padding: 2px 10px 1px;
    font-weight: 900;
    font-size: 12px;
}

table.campaigns-table .actions-cell .btn {
    padding: 0;
}

table.campaigns-table .actions-cell .btn.danger {
    color: #dc3545;
}

table.campaigns-table .actions-cell > *:not(:last-child) {
    margin-right: 8px;
}