.applicant-view {
    margin-top: 19px;
    padding:0;
    width: 1641px;
    margin-left: auto;
    margin-right: auto;
    min-width: 1220px;
}

.applicant-view>div {
    padding-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

.applicant-view .content-panel {
    overflow: hidden;
}

.applicant-view .content-panel>div {
    margin-left: 20px;
    float: left;
}

.applicant-view .content-panel>div:first-child {
    margin-left: 0;
}

.applicant-view .top-panel>div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.left-column {
    width: 397px;
    padding: 15px 30px 23px;
}

.left-column.column-card .card-heading {
    margin-bottom: 21px;
}

.left-column.column-card.status-blue {
    border-right: 13px solid #559AF4;
}

.left-column.column-card.status-orange {
    border-right: 13px solid #FF9900;
}

.left-column.column-card.status-turquoise {
    border-right: 13px solid #5BC0DE;
}

.left-column.column-card.status-green {
    border-right: 13px solid #42D253;
}

.left-column.column-card.status-red {
    border-right: 13px solid #F05B59;
}

.left-column.column-card.status-moccasin {
    border-right: 13px solid #FFE4B5;
}

.middle-column {
    width: 651px;
    padding: 15px 0px 1px;
}

.middle-column>div {
    padding-left: 30px;
    padding-right: 30px;
}

.right-column {
    width: 493px;
    padding: 15px 30px 23px;
}

.column-card {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #C6C6C6;
    font-family: 'Nunito Sans';
}

.column-card.right-fold {
    padding-right: 17px;
    border-right: 13px solid #559AF4;
}

.column-card .card-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.middle-column.column-card .card-heading, .middle-column.column-card .col {
    margin-bottom: 15px;
}

.column-card .card-heading a {
    color: #707070;
}

.dropdown-toggle::after {
    display: none;
}

.blacklist-info {
    font-family: "Nunito Sans";
    font-size: 14px;
    color: #FFFFFF;
    background-color: #1f3853;
    padding: 3px 9px 3px 9px;
    margin-top:15px;
}

.applicant-view .substatuses {
    cursor: default;
    margin-bottom: 3px;
    padding-left: 0;
}

.applicant-view .substatuses .status,
.applicant-view .substatuses .inline .input-wrapper {
    margin: 4px 5px 2px;
}

@media (max-width: 1869px) {
    .applicant-view {
        width: auto;
    }
}
