.campaign-view .content-panel {
    padding-top: 24px;
}
.campaign-view .content-footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    margin-top: 8px;
}
.campaign-view .content-footer button {
    width: 100px;
}