.mortgage-calculator-modal-footer {
    display: flex;
    align-items: flex-end;
}

.mortgage-calculator-modal-footer > *:not(:last-child) {
    margin-right: 8px;
}

.mortgage-calculator-modal-footer .generic-dropdown .btn.dropdown-toggle,
.mortgage-calculator-modal-footer .purpose input,
.mortgage-calculator-modal-footer button {
    height: 38px;
}

.mortgage-calculator-modal-footer .value {
    margin-bottom: 0;
}