/* The theme is actually based on boostrap dropdown dropdown-menu dropdown-item styling */

.react-autosuggest__suggestions-container {
    position: relative;
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
}

.react-autosuggest__input {
    min-width: max-content !important;
    padding-left: 0px;
    height: 32px;
}

.suggestion-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: fit-content;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    font-size: 14px;
    overflow-x: auto;
    width: 100%;
}

.suggestion-wrapper ul {
    margin: 0;
}

.suggestion-wrapper li {
    padding-left: 10px;
}

.global-search {
    font-family: 'Nunito Sans';
    flex-wrap: nowrap;
}

.suggestion-wrapper.global-search-wrapper {
    font-size: 14px;
}

.global-search-wrapper li {
    padding-left: 1.5rem;
}

.react-autosuggest__section-title {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
    background-color: #ddd;
}

.react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
}

.react-autosuggest__suggestion {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ccc;
}