.applicant-information {
    text-align: left;
    color: #707070;
    font-size: 14px;
    margin-right: 50px;
}

.applicant-information .applicant-name {
    font-family: 'Varela Round';
    font-size: 20px;
}

.applicant-information .applicant-name span.vamiddle {
    vertical-align: middle;
}

.applicant-information .applicant-name .co-applicant-marker {
    margin-left: 10px;
    opacity: 0.6;
}

.applicant-information .applicant-name .applicant-options {
    padding-left: 10px;
}

.applicant-information .applicant-name .dropright {
    display: inline-block;
}

.applicant-information .details {
    font-family: 'Nunito Sans';
    font-size: 14px;
    opacity: 0.6;
}

.applicant-information .contact-info>div {
    padding-right: 24px;
    display: flex;
    align-items: center;
}
 .applicant-information .contact-info .marketing-consent-revoked a {
     color: #c6c6c6;
 }

.applicant-information .contact-info .credit-info:not(:last-child) {
    margin-right: 17px;
}

.applicant-information .contact-info .address {
    clear: both;
    margin-right: 15px;
}

.applicant-information .contact-info svg {
    margin-right: 5px;
}

.contact-info>div+div>div {
    padding-top:2px;    
}

.applicant-information a {
    color: #707070;
    text-transform: lowercase;
}