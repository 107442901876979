.table {
    --bs-table-hover-bg: #f2f5f7;
}

.table-header {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f2f5f7;
    color: #9ea8b2;
}