

/* Some globally used bootstrap styling overrides */

.toast-container {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1;
}

.toast-header svg {
    margin-right: 10px;
}

/* overrides default bootstrap style */
.toast.show {
    display: flex;
}

.toast .icon {
    align-self: center;
    padding: 15px 5px 15px 10px
}

.toast .centered {
    align-self: center;
    padding: 10px;
}

.toast.error {
    background-color: white;
    border-left: #f05b59 solid 3px;
}

.toast.error .icon {
    color: #f05b59;
}

.toast.error .toast-header svg {
    color: #f05b59;
}

.toast.success {
    background-color: white;
    border-left: #42d253 solid 3px;
}
.toast.success .icon {
    color: #42d253;
}

.toast.success .toast-header svg {
    color: #42d253;
}