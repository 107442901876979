.multiselect {
    background-color: #F3F2F2;
    display: flex;
    font-family: 'Nunito Sans';
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    flex-wrap: wrap;
    border-radius: 4px;
    position: relative;
}

.multiselect:not(.inline) {
    min-height: 32px;
}

.multiselect:not(.inline) .input-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.multiselect:not(.inline) .multiselect-item + .input-wrapper:not(:has(input:focus, input:not([value=""]))) {
    width: 14px; /* width of the search icon */
    overflow: hidden;
}

.multiselect.inline {
    background: none;
}

.multiselect.inline .input-wrapper {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    transition: width .3s ease-out, border-radius .3s ease;
    overflow: hidden;
}

.multiselect.inline.search .input-wrapper {
    width: 100px;
    border-radius: 0.25em;
}

.multiselect.inline .inline-search-button {
    width: 100%;
    height: 100%;
    font-size: 12px;
    border: none;
    background-color: rgba(238, 238, 238, 1);
    color: #9EA8B2;
}

.multiselect input {
    border: 0;
    min-width: 30px;
    flex: 1;
}

.multiselect.inline input {
    font-size: 12px;
    padding-left: 10px;
    height: 100%;
    display: none;
}

.multiselect.inline.search input {
    display: block;
}

.multiselect:not(.inline) input {
    padding-right: 0;
}

.multiselect-item {
    font-size: 12px;
    font-weight: 900;
    display: flex;
    background-color: #C6C6C6;
    height: fit-content;
    min-height: 18px;
    border-radius: 0.25em;
    margin-top: 7px;
    margin-right: 8px;
    padding-left: 10px;
    padding-right: 5px;  
    align-content: baseline;
}

.multiselect-item button {
    display: block;
    margin-left: 10px;
    border: 0;
    padding: 0;
    background: none;
}

.multiselect.inline .multiselect-item button {
    width: 0;
    margin-left: 0;
    overflow: hidden;
    transition: width .3s ease, margin .3s ease;
}

.multiselect.inline .multiselect-item:hover button {
    width: 10px;
    margin-left: 8px;
}

.multiselect-item button>svg {
    display: block;
    width: 10px;
    height: 10px;
}

.multiselect-suggestion-wrapper {
    width: 100%;
}