.overlay {
    background-color: #14181B;
    opacity: 0.65;
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    z-index: 1;
    visibility: visible;
    transition:visibility 0.25s ease,opacity 0.25s ease;
}

.overlay.hidden  {
    visibility:hidden;
    opacity:0;
}