.reminders {
    position: fixed;
    bottom: 20px;
    left: 70px;
}

.reminders.minimized {
    position: fixed;
    bottom: 50%;
    transform: translateY(50%);
    left: 0px;
}

.reminders .unminimize-icon {
    color: #707070;
    background: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 3px 6px #677381;
    border: 1px solid #707070;
    padding: 15px;    
    display: none;
}

.reminders.minimized .unminimize-icon {
    display: block;
}

.reminders .unminimize-icon > span {
    font-size: 20px;
}

.reminders .unminimize-icon > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0, -100%);
    background-color: #F05B59;
    padding: 0px 6px;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
}

.reminders.minimized .unminimize-icon {
    display: block;
    cursor: pointer;
}

.reminders .reminder-item {
    background: white;
    display: flex;
    align-items: center;
    color: #707070;
    margin: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #707070;
    box-shadow: 0px 3px 6px #677381;
}

.reminders .reminder-item > div:first-child {
    opacity: 0;
}

.reminders .reminder-item:hover > div:first-child {
    opacity: 1;
}

.reminders.reminders.minimized .reminder-item {
    display: none;
}

.reminders .reminder-item > div {
    margin: 0 5px;
}

.reminders .reminder-item .reminder-customer-data {
    flex-grow: 1;
}

.reminders .reminder-item .react-datepicker-popper {
    min-width: 328px;
}

.reminders .reminder-item a {
    text-decoration: none;
    color: inherit;
}

.reminders .reminder-item .reminder-icon {
    margin:  10px;
}

.reminders .reminder-item .update-follow-up {
    margin-left: 5px;
    font-size: 25px;
    padding: 0 7px;
    color: #3490dc;
    border-radius: 5px;
    transition: 0.25s;
    opacity: 0.65;
    cursor: pointer;
}

.reminders .reminder-item .update-follow-up:hover {
    opacity: 1;
    transition: 0.25s;
}