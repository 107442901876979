button {
    --bs-btn-disabled-color: #F9F9F9;
    --bs-btn-disabled-border-color: transparent;
}

.btn.text-button {
    font-size: 12px;
    font-weight: 900;
    font-family: 'Nunito Sans';
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
    background-color: #3490dc;
    color: #ffffff;
}

.btn.btn-outline-primary {
    color: #3490dc;
    border-color: #3490dc;
}

.btn.btn-secondary,
.btn.btn-outline-secondary:hover {
    background-color: #6c757d;
    color: #ffffff;
}
.btn.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}

.btn:disabled {
    cursor: not-allowed;
}

form:invalid button.btn.submit-button {
    opacity: 0.65;
    pointer-events: none;
}

.btn.btn-primary.btn-white-bg:active,
.btn.btn-primary.btn-white-bg {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #333333;
}

.btn.btn-primary.btn-white-bg::after {
    display: none;
}
button.btn.btn-boolean-input {
    color: #333333;
    background-color: #eeeeee;
    border-color: #c6c6c6;
    height: 37px;
}

button.btn.btn-boolean-input.selected,
button:not([disabled]).btn.btn-boolean-input:hover {
    background-color: #ffffff;
}

.btn-boolean-input {
    font-size: 14px;
}

button.btn.btn-boolean-input {
    color: #c6c6c6;
    background-color: #ffffff;
}

button.btn.btn-boolean-input.selected {
    color: #ffffff;
    background-color: #3490dc;
}

button:not([disabled]).btn.btn-boolean-input.selected:hover {
    color: #ffffff;
    background-color: #3490dc;
}

.grey-button {
    color: #707070;
    cursor: pointer;
}

button:focus {
    box-shadow: none !important;
}