.half-sized-inputs-container {
    display: flex;
}

.half-sized-inputs-container > div {
    width: 50%;
}

.half-sized-inputs-container > div:first-child {
    padding-right: 10px;
}

.half-sized-inputs-container > div:last-child {
    padding-left: 9px;
}