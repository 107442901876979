.campaign-info {
    color: #707070;
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 50% calc(50% - 40px) 40px;
}

.campaign-info .campaign-name {
    font-family: Varela Round;
    font-size: 20px;
    text-align: left;
}

.campaign-info .campaign-details {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 8px;
}

.campaign-info .campaign-details > * {
    min-width: calc(33% - 8px);
}

.test-campaign-modal .description:not(:first-of-type) {
    margin-top: 12px;
}