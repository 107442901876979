.form-control:focus {
    box-shadow: none !important;
}

input.form-control,
textarea.form-control {
    font-size: 14px;
    resize: none;
}

.was-validated .invalid-feedback {
    display: block;
    margin-top: 2px;
    font-size: 12px;
    margin-left: 5px;
    font-weight: 700;
}

.was-validated.value .form-check .form-check-label {
    color: #212529;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.input-group-prepend {
    display: flex;
    border-radius: 0.375rem;
    overflow: hidden;
}

.input-group-prepend .input-group-text {
    border-radius: inherit;
}

.input-group-text.customized,
.form-control.customized {
    border: none;
    background-color: #f3f2f2;
    color: #333333;
}