.new-application {
    margin-top: 19px;
    padding:0;
    padding-bottom: 5px;
    width: 1641px;
    min-width: 950px;
}

.new-application>div {
    padding-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

.new-application>.top-panel {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.new-application>.top-panel>div{
    display: flex;
    min-height: 129px;
}

.new-application>.top-panel>div:first-child {
    flex: 1;
}

.new-application>.top-panel>div:last-child {
    min-width: 220px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
}

.new-application .property-information {
    margin-top: -20px;
}

.new-application.edit-mode .property-information {
    margin-bottom: 60px;
}

.new-application .property-information.right-4 {
    margin-right: 150px;
}

.new-application .property-information.right-5 {
    margin-right: 270px;
}

.new-application .loan-picker {
    min-width: 553px;
    max-height: 60px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.new-application>.top-panel .dropdown {
    margin-bottom: 10px;
}

.new-application>.top-panel .invalid-feedback {
    margin-top: -10px;
}

.new-application>.content-panel {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 24px;
}

.new-application .column-card {
    width: 380px;
    padding-top: 19px;
    padding-left: 29px;
    padding-right: 29px;
    margin-left: 24px;
}

.new-application .card-content {
    padding-top: 10px;
    padding-bottom: 8px;    
}

.new-application .card-content .divider {
    border-top: 1px solid #EEEEEE;
    height: 13px;
    width: 378px;
    margin-top: 9px;
    margin-left: -29px;
}

.new-application .card-content .short-divider {
    border-top: 1px solid #EEEEEE;
    height: 4px;
    width: 100px;
    margin: auto;
    margin-top: 10px;
}

.new-application .card-content .value {
    margin-bottom: 12px;
}

.new-application .card-content .loans-total .value {
    margin-bottom: 1px;
}

.new-application .card-content .value>span {
    margin-bottom: 9px;
}

.new-application .loans-row {
    display: flex;
    justify-content: space-between;
    color: #707070;
    font-weight: 700;
    font-size: 14px;
}

.new-application .loans-row>div:first-child {
    text-transform: capitalize;
}

.new-application .loans-row .grey-button {
    margin-left: 15px;
}

.new-application .loan-form .grey-button:not(:first-child) {
    margin-top: 10px;
    margin-bottom: -10px;
}

.new-application .loan-form:last-child > div:first-child {
    padding-bottom: 22px;
}

.new-application .loan-form {
    display: flex;
}

.new-application .loan-form .side-stripe {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.new-application .loan-form .side-stripe .source-icon {
    margin: 20px 0 15px 12px;
}

.new-application .loan-form>div:first-child {
    flex: 1;
}

.new-application .loan-form .user-tip {
    margin-top: 10px;
}

.new-application .loan-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px 0px 14px 12px;
}

.new-application .loans-total {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 14px;
}

.new-application .loans-total .value {
    text-align: center;
}

.new-application .loans-total .description {
    text-align: center;
}

.new-application>.content-panel>div:last-child {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}

.new-application .buttons button {
    min-width: 90px;
}

.new-application .buttons button+button {
    margin-left: 10px;
}

.new-application .multiselect {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    background-color: #ffffff;
    padding-top: 2px;
}

.new-application .multiselect .form-control {
    background-color: #ffffff;
    padding-top: 0;
    padding-bottom: 0;
}

@media (max-width: 1869px) {
    .new-application {
        width: auto;
    }
}
