.mortgage-calculator .loans-table tfoot tr {
    padding-left: 13px;
    border-top: 1px solid #707070;
}

.mortgage-calculator .loans-table tbody:after {
    content: "\2800";
    display: block;
    line-height: 8px;
}

.mortgage-calculator .loans-table {
    width: 100%;
    font-size: 14px;
    color: #707070;
}

.mortgage-calculator .loans-table .smooth span {
    font-weight: normal !important;
}

.mortgage-calculator .loans-table .input-group-text,
.mortgage-calculator .loans-table .form-control {
    font-size: 14px;
}

.mortgage-calculator .loans-table thead tr th:last-child,
.mortgage-calculator .loans-table tfoot tr th:last-child {
    padding: 6px 13px;
}